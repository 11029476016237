import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { withPage } from "../PageContainer";
import Selectors from "../Selectors";
import ActionCreator from "../ActionCreator";
import {
  HeaderFontCss,
  ParagraphFontCss,
  ButtonFontCss,
  DateFontCss,
} from "../Utils/CssStyleTemplateUtil";
import { s, d } from "../Utils/Lang";
import { monthNames } from "../Utils/DateUtil";
import { isDesktop, isPhone, isPad } from "../Utils/WindowUtil";
import { StaticImage } from "../Components/StaticImage";
import * as Widget from "../Components/Widget";
import { ArrowDown, ArrowUp } from "../Components/Icon";

class HistoryPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      articles: null,
      tag: "all",
      isPanelOpen: true,
    };
  }
  componentDidUpdate() {
    window && window.scrollTo(0, 0);
  }

  async componentDidMount() {
    let { appActions } = this.props;
    let articles = []; //await appActions.getArticles();
    this.setState({ articles });
    window.addEventListener("scroll", this._onScroll.bind(this));
    this.setState({ isPanelOpen: true });
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this._onScroll.bind(this));
  }

  _onScroll() {
    if (isPad() || isPhone()) {
      if (this.state.isPanelOpen) {
        this.setState({ isPanelOpen: false });
      }
    }
  }

  render() {
    let { history } = this.props.pageContext;
    let { articles } = this.state;

    return (
      <Wrapper isPanelOpen={this.state.isPanelOpen}>
        <div
          className="introduction-section"
          style={{
            width: "100%",
          }}
        >
          <div>
            {s("history-title") !== "" && this.state.isPanelOpen && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: 10,
                }}
              >
                <h1 className="title" style={{ fontSize: 26, marginBottom: 0 }}>
                  {s("history-title")}
                </h1>
                {(isPhone() || isPad()) && (
                  <ArrowComp
                    setOpen={s => this.setState({ isPanelOpen: s })}
                    isOpen={this.state.isPanelOpen}
                    extraStyle={{}}
                  />
                )}
              </div>
            )}
            {s("history-introduction") !== "" && this.state.isPanelOpen && (
              <div style={{}}>{s("history-introduction")}</div>
            )}
          </div>

          {this.state.isPanelOpen && (
            <Widget.Button
              css={`
                margin-top: 15px !important;
                color: white;
                background-color: ${"all" === this.state.tag
                  ? "#f36a26"
                  : "grey"};
              `}
              onClick={() => {
                this.setState({ tag: "all" });
                isPhone() &&
                  this.state.isPanelOpen &&
                  this.setState({ isPanelOpen: false });
              }}
            >
              {s("tag-all")}
            </Widget.Button>
          )}

          {this.state.isPanelOpen && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                width: "100%",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              {["works", "report", "open-source", "sharing"].map((tag, idx) => (
                <Widget.Button
                  key={idx}
                  css={`
                    margin-top: 15px !important;
                    color: white;
                    width: 48%;
                    background-color: ${tag === this.state.tag
                      ? "#f36a26"
                      : "grey"};
                  `}
                  onClick={() => {
                    this.setState({ tag });
                    isPhone() &&
                      this.state.isPanelOpen &&
                      this.setState({ isPanelOpen: false });
                  }}
                >
                  <h2
                    style={{
                      fontSize: 20,
                      margin: 0,
                      fontWeight: 400,
                      textAlign: "center",
                    }}
                  >
                    {s("tag-" + tag)}
                  </h2>
                </Widget.Button>
              ))}
            </div>
          )}

          {isPhone() && !this.state.isPanelOpen && (
            <ArrowComp
              setOpen={s => this.setState({ isPanelOpen: s })}
              isOpen={this.state.isPanelOpen}
              extraStyle={{ marginTop: 20 }}
            />
          )}
        </div>
        <div
          className="history-list-section"
          style={isPhone() && !this.state.isPanelOpen ? { paddingTop: 10 } : {}}
        >
          {this._convertData(history, articles).map((h, idx) => (
            <div key={idx} className="history-container">
              {(() => {
                let content = h.items.sort((a, b) => b.id - a.id);

                if (this.state.tag !== "all") {
                  content = content.filter(
                    c =>
                      (c.tags || [this.state.tag]).indexOf(this.state.tag) >= 0
                  );
                }

                if (content.length > 0) {
                  return (
                    <>
                      <div className="title">{h.year}</div>
                      {content.map((item, i) => (
                        <HistoryItem key={i} item={item} />
                      ))}
                    </>
                  );
                } else {
                  return <div />;
                }
              })()}
            </div>
          ))}
        </div>
      </Wrapper>
    );
  }

  _convertData = (history, articles) => {
    if (!articles) {
      return history;
    }

    let result = [...history];

    articles
      .sort(
        (a, b) => new Date(a.created).getTime() - new Date(b.created).getTime()
      )
      .forEach((article, idx, array) => {
        let desp =
          article.sections.length > 0
            ? article.sections
                .filter(section => section.type === "content")
                .map(section => section.value)
                .toString()
            : "";
        let created = new Date(article.created);

        let convertArticleData = {
          ...article,
          id: article.id,
          title_tw: article.title,
          title_en: article.title,
          description_tw: desp,
          description_en: desp,
          created: `${
            monthNames[created.getMonth()]
          } / ${created.getFullYear()}`,
          image: article.image,
          link: "",
        };

        let year = created.getFullYear();
        let yearDataIdx = result.findIndex(h => h.year === year);
        let haveItemData =
          yearDataIdx > -1
            ? result[yearDataIdx].items.some(
                item => item.id === convertArticleData.id
              )
            : null;

        if (yearDataIdx > -1 && !haveItemData) {
          result[yearDataIdx].items.unshift(convertArticleData);
        } else if (yearDataIdx === -1 && !haveItemData) {
          result.unshift({ year, itmes: [convertArticleData] });
        }
      });

    return result;
  };
}

const ArrowComp = ({ setOpen, isOpen, extraStyle = {} }) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
        ...extraStyle,
      }}
      onClick={() => setOpen(!isOpen)}
    >
      <div
        style={{
          backgroundColor: "#E8D5C4",
          width: 45,
          height: 45,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: 22.5,
        }}
      >
        {isOpen && <ArrowUp size="32" color="#F0A04B" />}
        {!isOpen && <ArrowDown size="32" color="#F0A04B" />}
      </div>
    </div>
  );
};

const Wrapper = styled.div`
  position: relative;
  display: flex;

  @media screen and (max-width: 900px) {
    flex-direction: column;
  }

  .introduction-section {
    background-color: #f1f1f1;
    padding: 30px;
    color: #4e4e4e;
    display: flex;
    flex-direction: column;
    flex-basis: 360px;
    flex-grow: 0;
    flex-shrink: 0;

    & h2 {
      letter-spacing: 2px;
      font-size: 26px;
      ${HeaderFontCss}
      margin-bottom: 20px;
    }

    & p {
      font-size: 14px;
      ${ParagraphFontCss}
      white-space: pre-wrap;
      text-align: justify;
      line-height: 20px;
    }

    & button {
      margin-top: 40px;
      align-self: flex-start;
    }
  }

  .history-list-section {
    flex-grow: 1;

    & .history-container {
      & .title {
        ${HeaderFontCss}
        padding: 10px 18px;
        min-height: 50px;
        letter-spacing: 0.95px;
        font-size: 35px;
        color: #f36a26;
      }
    }
  }

  @media screen and (max-width: 900px) {
    .introduction-section {
      position: fixed;
      z-index: 5;
      height: ${props => (props.isPanelOpen ? "450px" : "100px")};

      display: flex;
      flex-direction: column;
      justify-content: center;

      padding: 30px 40px 40px;
      flex-basis: initial;

      flex-grow: 1;

      & h2 {
        letter-spacing: 2px;
        text-align: left;
      }

      & button {
        align-self: center;
      }
    }

    .history-list-section {
      margin-top: ${props => (props.isPanelOpen ? "450px" : "100px")};
    }
  }

  @media screen and (max-width: 500px) {
    .introduction-section {
      padding: 45px 25px 50px;
      & .button {
        margin-top: 30px;
      }
    }

    .history-list-section {
    }
  }
`;

class HistoryItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mount: false,
    };
  }

  componentDidMount() {
    this.setState({ mount: true });
  }

  render() {
    let { item, navActions } = this.props;

    return (
      <HistoryItemWrapper>
        <div className="img-container">
          <a
            href={item.link}
            target="_blank"
            ariaLabel={this._limitDespForScreen(d(item, "description"))}
            style={{ width: "100%" }}
            alt={d(item, "title")}
            aria-label={d(item, "title")}
          >
            {item.image.indexOf("http") > -1 ||
            item.image.indexOf("https") > -1 ? (
              <div
                style={{
                  backgroundImage: `url(${item.image})`,
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  height: 220,
                }}
              />
            ) : (
              <StaticImage
                path={"/history/"}
                name={item.image}
                alt={item.imageAlt}
                style={{ height: 220 }}
              />
            )}
          </a>
        </div>
        <div className="info-container">
          <h3 style={{ fontWeight: "normal", fontSize: 20 }}>
            {this._limitTitleForScreen(d(item, "title"))}
          </h3>
          <div className="content">
            <div className="paragraph">
              {this._limitDespForScreen(d(item, "description"))}
            </div>
            <div className="row">
              <div className="date">{item.created}</div>
              {item.link != null &&
                (item.link ? (
                  <a
                    className="button"
                    href={item.link}
                    target="_blank"
                    alt={d(item, "title")}
                    aria-label={d(item, "title")}
                  >
                    {item.line === "-" ? "" : s("read-more")} >
                  </a>
                ) : (
                  <div
                    className="button"
                    alt={d(item, "title")}
                    aria-label={d(item, "title")}
                    onClick={() =>
                      navActions.push(`/history-detail/?id=${item.id}`)
                    }
                  >
                    {s("read-more")} >
                  </div>
                ))}
            </div>
          </div>
        </div>
      </HistoryItemWrapper>
    );
  }

  _limitTitleForScreen = text => {
    if (this.state.mount) {
      if (window.screen.width <= 1380 && window.screen.width > 1250) {
        return text.length >= 42 ? text.slice(0, 40) + "..." : text;
      } else if (window.screen.width <= 1250 && window.screen.width > 1180) {
        return text.length >= 22 ? text.slice(0, 20) + "..." : text;
      } else if (window.screen.width <= 1180 && window.screen.width > 950) {
        return text.length >= 14 ? text.slice(0, 12) + "..." : text;
      } else if (window.screen.width <= 950 && window.screen.width > 900) {
        return text.length >= 8 ? text.slice(0, 6) + "..." : text;
      } else if (window.screen.width <= 900 && window.screen.width > 600) {
        return text.length >= 28 ? text.slice(0, 26) + "..." : text;
      } else if (window.screen.width <= 900 && window.screen.width > 600) {
        return text.length >= 14 ? text.slice(0, 12) + "..." : text;
      } else if (window.screen.width <= 600 && window.screen.width > 500) {
        return text.length >= 10 ? text.slice(0, 8) + "..." : text;
      }
    }

    return text.length >= 56 ? text.slice(0, 54) + "..." : text;
  };

  _limitDespForScreen = text => {
    if (this.state.mount) {
      if (window.screen.width <= 1380 && window.screen.width > 1250) {
        return text.length >= 254 ? text.slice(0, 252) + "..." : text;
      } else if (window.screen.width <= 1250 && window.screen.width > 1180) {
        return text.length >= 202 ? text.slice(0, 200) + "..." : text;
      } else if (window.screen.width <= 1180 && window.screen.width > 960) {
        return text.length >= 80 ? text.slice(0, 78) + "..." : text;
      } else if (window.screen.width <= 960 && window.screen.width > 900) {
        return text.length >= 50 ? text.slice(0, 48) + "..." : text;
      } else if (window.screen.width <= 900 && window.screen.width > 600) {
        return text.length >= 200 ? text.slice(0, 198) + "..." : text;
      } else if (window.screen.width <= 600 && window.screen.width > 500) {
        return text.length >= 80 ? text.slice(0, 78) + "..." : text;
      }
    }

    return text.length >= 458 ? text.slice(0, 456) + "..." : text;
  };
}

const HistoryItemWrapper = styled.div`
  display: flex;
  border-bottom: 1px solid #ffffff;

  & .img-container {
    width: 50%;
    background-color: #eeeeee;
    display: flex;
    justify-cotent: center;
    align-items: center;
  }
  & .info-container {
    width: 50%;
    max-height: 50%;
    background-color: #f1f1f1;
    padding: 35px 30px 25px;
    display: flex;
    flex-direction: column;

    h3 {
      ${HeaderFontCss}
      letter-spacing: 0.95px;
      line-height: 35px;
      font-weight: 500;
      font-size: 35px;
      color: #4e4e4e;
      margin-bottom: 30px;
    }

    .content {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .paragraph {
        ${ParagraphFontCss}
        letter-spacing: 0.4px;
        line-height: 20px;
        text-align: justify;
        font-size: 14px;
        color: #4e4e4e;
        margin-bottom: 5px;
      }

      .row {
        display: flex;
        justify-content: space-between;

        .date {
          ${DateFontCss}
          letter-spacing: 0.4px;
          line-height: 20px;
          font-size: 14px;
          color: #4e4e4e;
        }

        .button {
          ${ButtonFontCss}
          border: none;

          &:hover,
          &:focus {
            outline: none;
          }

          cursor: pointer;
          text-decoration: none;
          background-color: #f1f1f1;
          line-height: 20px;
          letter-spacing: 0.4px;
          font-size: 14px;
          color: #4e4e4e;
        }
      }
    }
  }

  @media screen and (max-width: 900px) {
    & .info-container {
      h3 {
        font-size: 30px;
        margin-bottom: 15px;
      }

      .content {
        .paragraph {
        }
      }
    }
  }

  @media screen and (max-width: 500px) {
    flex-direction: column;
    & .img-container {
      width: 100%;
      max-height: 100%;
    }

    & .info-container {
      width: 100%;
      height: 100%;
    }
  }
`;

HistoryItem = connect(
  (state, ownProps) => ({
    lang: Selectors.getLang(state),
  }),
  ActionCreator
)(HistoryItem);

export default withPage(
  connect((state, ownProps) => ({}), ActionCreator)(HistoryPage)
);
